import React from "react";
import logo from "../../images/marketing-agency-website-web-graphic-design-SEO-Google-Facebook-Instagram-LinkedIn-consulting.png";
import logo1 from '../../images/logo_test1.png'
function Logo(props) {
  var logo_tag = "";
  if(props.prefix_string_page === "en"){
    logo_tag = "Digital Marketing Agency with services for consulting, website and graphic design, SEO, Google Ads and social media (Facebook, Instagram, Linkedin, Twitter...) advertising";
  }
  else if(props.prefix_string_page === "bs"){
    logo_tag = "Agencija za digitalni marketing sa uslugama konzaltinga, web i grafičkog dizajna, SEO, Google oglašavanja i reklamiranja putem društvenih mreža - Facebook, Instagram, Linkedin, Twitter...";
  }

  return <img src={logo} alt={logo_tag} title={logo_tag} />;
}

//DEFAULT MAIN LOGO//
export default Logo;